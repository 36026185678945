<template>
  <vx-card
    title-color="#fff"
    content-color="#fff"
    class="contained-example-container"
    card-background="#262c49"
  >
    <div class="header">
      <div class="row">
        <div>
          <h4 class="text-white">{{ title }}</h4>
        </div>
        <div>
          <div class="actions">
            <!-- <DatepickerRangePopup
              ref="datepickerRangePopup"
              @save="saveRangeInterval"
            />-->
            <div slot="actions">
              <span @click="download">
                <vs-icon
                  v-tooltip="lang.metrics.download[languageSelected]"
                  icon="icon-download"
                  icon-pack="feather"
                  style="cursor: pointer"
                ></vs-icon>
              </span>
              <!-- <metric-dropdown
                :on-last-week="updateInterval"
                :on-last-month="updateInterval"
                :on-range-select="openRangeInterval"
              />-->
            </div>
          </div>
        </div>
      </div>
      <div>
        <h6 class="description">{{ description }}</h6>
      </div>
    </div>

    <div
      class="p-6 pb-base vs-con-loading__container"
      :id="`div-with-loading-${report._id}`"
    >
      <div id="cardbox" class="mb-base mt-base">
        <vue-funnel-graph
          :key="width"
          v-if="showFunnelGraph"
          :width="width"
          :height="height"
          :labels="labels"
          :values="values"
          :colors="colors"
          :sub-labels="subLabels"
          :direction="direction"
          :gradient-direction="gradientDirection"
          :animated="true"
          :display-percentage="true"
        ></vue-funnel-graph>
      </div>
    </div>
  </vx-card>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import VueFunnelGraph from '../../../../../components/vue-funnel-graph/funnel-graph-js'
const moment = require('moment')
const Json2csvParser = require('json2csv').Parser

export default {
  name: 'FunnelByPlatformV1',
  props: {
    report: {
      type: Object,
      required: true
    },
    type: {
      type: Array
    },
    platforms: {
      type: Array
    },
    services: {
      type: Array,
      required: true
    },
    interval: Object,
    versions: {
      type: Array,
      required: true
    }
  },
  components: {
    VueFunnelGraph
  },
  data() {
    return {
      Json2csvParser,
      subLabels: [
        'Facebook',
        'Web',
        'WhatsApp',
        'Twitter',
        'RCS',
        'Signal',
        'Slack',
        'Skype',
        'Google_Assistant',
        'Alexa',
        'Voice',
        'Workplace',
        'Hangouts',
        'Telegram'
      ],
      values: [[], []],
      colors: [['#FFB178', '#FF3C8E'], ['#A0F9FF', '#7795FF']],
      direction: 'horizontal',
      gradientDirection: 'horizontal',
      height: 200,
      width: '',
      nextWidth: ''
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    sidebarWidth() {
      return this.$store.state.sidebarWidth
    },
    title() {
      const found = this.report.name.find(element => {
        return element.lang === this.languageSelected
      })
      if (found) {
        return found.text
      }
      return 'N/A'
    },
    description() {
      const found = this.report.description.find(element => {
        return element.lang === this.languageSelected
      })
      if (found) {
        return found.text
      }
      return 'N/A'
    },
    labels() {
      const labels = ['1º', '2º']
      if (!this.report || !this.report._extra) {
        return labels
      }
      if (this.report._extra.label1) {
        const found = this.report._extra.label1.find(element => {
          return element.lang === this.languageSelected
        })
        if (found) {
          labels[0] = found.text
        }
      }
      if (this.report._extra.label2) {
        const found = this.report._extra.label2.find(element => {
          return element.lang === this.languageSelected
        })
        if (found) {
          labels[1] = found.text
        }
      }
      return labels
    },
    showFunnelGraph() {
      // required funnel props
      return (
        this.width !== '' &&
        this.labels.length > 0 &&
        this.values[0] &&
        this.values[0].length > 0 &&
        this.values[1] &&
        this.values[1].length > 0
      )
    }
  },
  watch: {
    services() {
      this.load(this.report)
    },
    interval() {
      this.load(this.report)
    },
    platforms() {
      this.load(this.report)
    },
    versions() {
      this.load(this.report)
    },
    async sidebarWidth() {
      setTimeout(() => {
        var element = document.getElementById('cardbox')
        this.width = element.offsetWidth
      }, 500)
    }
  },
  methods: {
    ...mapActions('metrics', ['getReportData']),
    download() {
      function saveData(blob, fileName) {
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
      }

      let fields = ['Level']
      fields = fields.concat(this.subLabels)
      fields = fields.concat(['Total'])

      let data = []
      this.values.forEach((element, i) => {
        data.push({
          Level: this.labels[i]
        })
        let total = 0
        element.forEach((item, j) => {
          data[data.length - 1][this.subLabels[j]] = parseInt(item)
          total += data[data.length - 1][this.subLabels[j]]
        })
        data[data.length - 1]['Total'] = total
      })

      const json2csvParser = new Json2csvParser({ fields })
      const csv = json2csvParser.parse(data)

      let filename = `konecta_metric_${this.title}_`
      filename += new Date().toISOString()

      var BOM = '\uFEFF'
      var csvData = BOM + csv

      var blob = new Blob([csvData], {
        type: 'text/plain; encoding=utf-8'
      })

      saveData(blob, `${filename}.csv`)
    },
    async load(report) {
      if (!this.services || this.services.length === 0) {
        return
      }
      if (!this.versions || this.versions.length === 0) {
        return
      }
      if (!this.platforms || this.platforms.length === 0) {
        return
      }
      const toDateStr =
        this.interval && moment(this.interval.to).format('YYYY-MM-DD HH:mm:ss')
      const fromDateStr =
        this.interval &&
        moment(this.interval.from).format('YYYY-MM-DD HH:mm:ss')
      const data = {
        type: this.type,
        services: this.services,
        versions: this.versions,
        from: fromDateStr,
        to: toDateStr,
        platforms: this.platforms
      }
      this.$vs.loading({
        container: `#div-with-loading-${report._id}`,
        scale: 1.0
      })
      try {
        const result = await this.getReportData({
          reportId: report._id,
          payload: data
        })
        if (result.data[0].length === 0) {
          this.values = [[], []]
          this.$vs.loading.close(
            `#div-with-loading-${report._id} > .con-vs-loading`
          )
          return
        }

        // Procesamiento
        const platforms = result.data[0].map(element => element.platform)
        const values1 = platforms.map(() => 0)
        const values2 = platforms.map(() => 0)
        platforms.forEach((platform, index) => {
          let found = result.data[0].find(element => {
            return element.platform === platform
          })
          if (found) {
            values1[index] = parseInt(found.count, 10)
          }
          found = null
          found = result.data[1].find(element => {
            return element.platform === platform
          })
          if (found) {
            values2[index] = parseInt(found.count, 10)
          }
        })
        this.subLabels = platforms
        this.values = [values1, values2]

        this.$vs.loading.close(
          `#div-with-loading-${report._id} > .con-vs-loading`
        )
      } catch (ex) {
        if (ex.message !== 'cancelled') {
          this.$vs.loading.close(
            `#div-with-loading-${report._id} > .con-vs-loading`
          )
          const reportName =
            this.languageSelected === 'es'
              ? report.name[0].text
              : report.name[1].text
          this.$vs.notify({
            text:
              this.lang.metrics.reportMessages.text[this.languageSelected] +
              `"${reportName}"`,
            color: 'danger'
          })
          console.error(ex)
        }
      }
    }
  },
  async mounted() {
    var element = document.getElementById('cardbox')
    this.width = element.offsetWidth
    this.load(this.report)
  }
}
</script>
